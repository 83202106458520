<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <span>Use Cases / Documents Insight</span>
      </div>
      <div>
        <button class="open_sidebar me-2" @click="toggleSidebar">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >

          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <div class="container">
          <div class="container-header">
            <h6 class="container-title">Documents Insight</h6>
            <span class="container-description">
              Upload documents and let NeXa handle the heavy lifting.
            </span>
          </div>
          <UploadFiles
            @get-file="getFile"
            @get-jurisdiction="getJurisdiction"
            @get-query="getQuery"
            not-send
          />

          <div class="footer-upload">
            <button
              :disabled="!isAllFieldValid || isSubmitting"
              style="display: flex; align-items: center; gap: 8px"
              :class="{
                'button-generate': isAllFieldValid,
                'button-generate-muted': !isAllFieldValid,
              }"
              @click="uploadFile"
            >
              Generate Insights
              <b-spinner
                v-if="isSubmitting"
                variant="primary"
                style="scale: 0.75"
                class="material-symbols-rounded"
              />
            </button>
          </div>
        </div>
      </div>
    </div>
    <sidebar
      v-clickaway="toggleSidebar"
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
    />
  </div>
</template>

<script>
import usecase from "@/store/usecase.js";
import sidebar from "@/Pages/Mode//sidebar.vue";
import UploadFiles from "@/components/input/UploadFiles.vue";
import { getWordCount } from "@/store/utils.js";
export default {
  components: {
    UploadFiles,
    sidebar,
  },
  data() {
    return {
      isSubmitting: false,
      loading: true,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      files: [[], [], []],
      askQuestion: "",
      uploadedFilesCount: 0,
      showform: false,
      legalQuery: "",
      jurisdiction: {},
    };
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 7000);
  },
  methods: {
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    getWordCount(text) {
      return getWordCount(text);
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },

    updateFiles(newFiles) {
      this.$set(this.files, 0, newFiles);
      this.uploadedFilesCount = newFiles.length;
    },
    getFile(file) {
      this.files = file;
    },
    getJurisdiction(jurisdiction) {
      this.jurisdiction = jurisdiction;
    },
    getQuery(query) {
      this.askQuestion = query;
    },

    toggleForm() {
      this.showform = !this.showform;
    },
    async uploadFile() {
      if (this.files[2] && this.files[2].length < 0) {
        this.$toast.error("Please upload at least one file.");
        return;
      }

      this.isSubmitting = true;
      try {
        const formData = new FormData();

        this.files[2].forEach((file, index) => {
          formData.append(`question_doc[${index}]`, file.file);
        });

        const countryMapping = {
          AUS: "Australia",
          USA: "USA",
          CAN: "Canada",
          NZ: "New Zealand",
          SG: "Singapore",
          MY: "Malaysia",
          UK: "United Kingdom",
        };
        const country = countryMapping[Object.keys(this.jurisdiction)[0]];
        const locations = localStorage.getItem("locations")
          ? JSON.parse(localStorage.getItem("locations"))
          : [];
        const foundLocation = locations.find((x) => x.title === country);

        formData.append("jurisdiction", JSON.stringify(this.jurisdiction));
        formData.append("question", this.askQuestion);
        formData.append("language", "en");
        formData.append("location_id", foundLocation.id);
        formData.append("type", "document_insights");

        const response = await usecase.CreateUsecase(formData);
        if (response && response.data) {
          this.$toast.success("Successfully uploaded files.");
          this.$router.push({
            name: "DocumentInsightResult",
            params: { id: response.data.data.id },
          });
        } else {
          this.$toast.error("Upload failed. Please try again.");
        }
      } catch (error) {
        this.isSubmitting = false;
        this.$toast.error(
          "An error occurred while uploading files. Please try again."
        );
      }
    },
  },
  computed: {
    isAllFieldValid() {
      return Object.keys(this.jurisdiction).length > 0 &&
        this.askQuestion.trim() &&
        this.files[2]?.length > 0
        ? true
        : false;
    },
  },
};
</script>

<style scoped>
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.container {
  display: flex;
  width: 100%;
  padding: 1.375rem 1.5rem;
  margin-top: 1.35rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  border-radius: 0.5rem;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 2px 4px 0px rgba(170, 171, 175, 0.6);
}

.container-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.25rem;
  align-self: stretch;
}
.container-title {
  color: var(--Primary-Blue, #0e4485) !important;
}

.container-description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.page-content {
  padding: 0;
}
.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 5px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
  span {
    font-size: 14px;
    color: var(--dark-grey) !important;
  }
}
.open_sidebar {
  display: inline-flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center; /* Ensure vertical alignment */
  gap: 8px;
  border-radius: 4px;
  background: var(--primary);
}

.open_sidebar span {
  color: #fff;
  display: flex; /* Ensure icon is aligned vertically */
  align-items: center; /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0; /* Remove any default margin to align the text properly */
  line-height: 1; /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center; /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}
.back {
  color: var(--primary) !important;
  font-weight: 500;
  padding: 5px;
}
.col-md-10 {
  max-height: 100vh; /* Ensure it doesn’t exceed the viewport height */
}
</style>

<style scoped>
.left-container {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
  overflow: hidden;
}

@media (max-width: 991px) {
  .left-container {
    padding: 0 20px;
  }
}

.tabs {
  display: flex;
  flex-direction: column;
  font-family: Poppins, sans-serif;
  justify-content: center;
}

.tabs-title {
  color: #0e4485;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}
.tabs-description {
  color: #86888d;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.query-section {
  margin-top: 32px;
  font: 16px/28px Poppins, sans-serif;
}
.case-file {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font-weight: 500;
  justify-content: center;
}

.case-file-title {
  margin-bottom: 4px;
}

.content {
  color: #d1d2d5;
  font-weight: 400;
}
.searchbar {
  border: 1px solid #f2f3f3;
  border-radius: 8px;
  background-color: #fafbfc;
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

.entry {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.legal-input {
  background: none;
  width: 100%;
}

.line {
  border: 1px solid #000;
  height: 28px;
  align-self: start;
}

.char-count {
  align-self: end;
  margin-top: 40px;
}
.upload-file {
  margin-top: 32px;
  flex-direction: column;
  justify-content: start;
}

.upload-section {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font: 500 16px/28px Poppins, sans-serif;
}
.upload-title {
  margin-bottom: 8px;
}

.upload-description {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
}

.upload {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  cursor: pointer;
}
.upload-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #d1d2d5;
  border-radius: 8px;
  background-color: #fafbfc;
  min-width: 240px;
  padding: 27px 80px;
}
.upload-figure {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-image {
  width: 32px;
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
}
.upload-caption {
  color: #0e4485;
  font-size: 14px;
  font-weight: 500;
  margin-top: 5px;
}

.upload-choose-file {
  color: #0e4485;
}

.upload-file-format {
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  margin-top: 5px;
}

.upload-instruction {
  background-color: #fafbfc;
  border-radius: 8px;
  min-width: 240px;
  padding: 58px 70px;
  box-shadow: 0px 0px 4px rgba(242, 243, 243);
  color: #86888d;
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
}

.storage-info {
  color: #86888d;
  font: 400 12px/24px Poppins, sans-serif;
  margin-top: 8px;
}

.folder-creation {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  justify-content: start;
  flex-wrap: wrap;
}
.toggle {
  align-items: center;
  display: flex;
}

.toggle-switch {
  width: 40px;
  height: 20px;
  border-radius: 100px;
  background-color: #d1d2d5;
  position: relative;
}
.toggle-checkbox {
  opacity: 0;
  position: absolute;
}

.toggle-checkbox:checked + .toggle-switch {
  background-color: #0e4485;
}

.toggle-checkbox:checked + .toggle-switch::before {
  transform: translateX(20px);
}

.toggle-switch::before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 100px;
  background-color: #fff;
  position: absolute;
  transition: transform 0.3s;
  box-shadow: 0px 2px 4px rgba(39, 39, 39, 0.1);
}

.toggle-label {
  font: 400 14px/24px Poppins, sans-serif;
  color: #383a3e;
}
.generate-button {
  align-self: end;
  border-radius: 4px;
  background-color: rgba(14, 68, 133, 0.1);
  color: #d1d2d5;
  padding: 4px 16px;
  margin-top: 32px;
  font: 500 16px Poppins, sans-serif;
}
</style>
<style scoped>
.uploads {
  background-color: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  margin-top: 24px;
  h5 {
    color: var(--Primary-Blue, #0e4485) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
  }
}
.sub-title {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 200% */
}
.right-container {
  height: 182px;
  display: flex;
  padding: 0px 53px 0px 99px;
  justify-content: flex-end;
  align-items: center;
  flex: 1 0 0;
  border-radius: 8px;
  background: var(--Neutral-Interface-Grey, #fafbfc);
  box-shadow: 0px 0px 4px 0px #f2f3f3;
  p {
    color: var(--Neutral-Dark-Grey, #86888d) !important;

    /* Small Paragraph */
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 200% */
  }
}

.toggle {
  display: flex;
  width: 40px;
}
.switch {
  flex-grow: 1;
  background: var(--Neutral-Mid-Grey, #d1d2d5);
  border-radius: 100px;
  padding: 2px;
  display: flex;
  align-items: center;
}
.button {
  width: 16px;
  height: 16px;
  background: var(--Neutral-White, #fff);
  border-radius: 100px;
  box-shadow: 0 2px 4px rgba(39, 39, 39, 0.1);
}
.swift:hover {
  background: rgba(24, 144, 255, 0.1);
  display: flex;
  border-radius: 4px;
  padding: 8px 16px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}
.upload {
  border-radius: 4px;
  background: rgba(14, 68, 133, 0.1);
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--mid-grey);
}
.upload:hover {
  background: var(--primary);
}
</style>
<style scoped>
.left-container {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 2px 4px 0px rgba(170, 171, 175, 0.6);
  background-color: var(--Neutral-White, #fff);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-family: Poppins, sans-serif;
  justify-content: start;
  padding: 22px 24px;
}
@media (max-width: 991px) {
  .left-container {
    padding: 0 20px;
  }
}

.header {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: start;
}
@media (max-width: 991px) {
  .header {
    max-width: 100%;
  }
}

.tabs {
  align-self: start;
  font-size: 16px;
  color: var(--Primary-Blue, #0e4485);
  font-weight: 500;
  line-height: 28px;
}
.description {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-size: 12px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 4px;
}

@media (max-width: 991px) {
  .description {
    max-width: 100%;
  }
}
.query-section {
  display: flex;
  margin-top: 32px;
  width: 100%;
  flex-direction: column;
  font-size: 16px;
  justify-content: start;
}

@media (max-width: 991px) {
  .query-section {
    max-width: 100%;
  }
}

.seachbar {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  background-color: var(--Neutral-White, #fff);
  display: flex;
  margin-top: 4px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: start;
}

@media (max-width: 991px) {
  .seachbar {
    max-width: 100%;
  }
}

.footer {
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  background: var(--Neutral-Interface-Grey, #fafbfc);
  background-color: var(--Neutral-Interface-Grey, #fafbfc);
  display: flex;
  width: 100%;
  gap: 40px 100px;
  overflow: hidden;
  flex-wrap: wrap;
  padding: 8px 20px;
}

@media (max-width: 991px) {
  .footer {
    max-width: 100%;
  }
}
.file-info {
  border-radius: 8px;
  background-color: rgba(24, 144, 255, 0.1);
  align-self: stretch;
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  color: var(--Neutral-Dark-Grey, #86888d);
  justify-content: start;
  margin: auto 0;
  padding: 4px 16px;
}
.button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  font-weight: 500;
  justify-content: start;
  margin: auto 0;
  padding: 6px 8px;
}

.img {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
  margin: auto 0;
}

.button-title {
  align-self: stretch;
  margin: auto 0;
}

.file-count {
  font-weight: 400;
  line-height: 28px;
  align-self: stretch;
  margin: auto 0;
}

.file-limit {
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  font-weight: 400;
  line-height: 28px;
  align-self: stretch;
  margin: auto 0;
}

.footer-upload {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  align-self: stretch;
}

.button-generate {
  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
  background-color: var(--Primary-Blue, #0e4485);
  /* margin-top: 32px; */
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  color: var(--Neutral-White, #fff);
  font-weight: 500;
  padding: 4px 16px;
}

.button-generate-muted {
  border-radius: 4px;
  color: var(--Neutral-Mid-Grey, #d1d2d5);
  background: rgba(14, 68, 133, 0.1);
  /* margin-top: 32px; */
  gap: 8px;
  overflow: hidden;
  font-size: 16px;
  font-weight: 500;
  padding: 4px 16px;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
</style>
